/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'Invoer is niet geldig',
    required: 'Dit veld is verplicht',
  },
  array: {
    min: 'Dit veld moet minimaal ${min} waarde(s) bevatten',
    max: 'Dit veld mag maximaal ${min} waarde(s) bevatten',
  },
  string: {
    length: 'Dit veld moet exact ${length} karakter(s) zijn',
    min: 'Dit veld moet minimaal ${min} karakter(s) zijn',
    max: 'Dit veld mag maximaal ${max} karakter(s) zijn',
    matches: 'Dit veld moet voldoen aan patroon: "${regex}"',
    email: 'Dit is geen geldig email adress',
    url: 'Dit is geen geldige URL',
    uuid: 'Dit is geen geldige UUID',
    trim: 'Dit is geen geldige waarde',
    lowercase: 'De waarde moet lowercase zijn',
    uppercase: 'De waarde moet upper case zijn',
  },
});
