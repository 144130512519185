import 'intersection-observer';
import 'element-remove';

import ResizeObserver from 'resize-observer-polyfill';
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

require('./a11y');
